import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import styles from './index.module.scss'
import Layout from 'components/layout'
import SEO from 'components/seo'
import intl from '@/intl'
import toTitleCase from 'titlecase'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MARKS, BLOCKS, INLINES } from '@contentful/rich-text-types'
import { getSources } from '@/utils'
import LoopScroll from 'components/loop-scroll'

const AboutPage = (props) => {
  const parent = React.createRef()
  const title = props.data.allContentfulAbout.nodes[0].title
  const mainImage = props.data.allContentfulAbout.nodes[0].mainImage
  const Bold = ({ children }) => <span className={`font-sans uppercase`}>{children}</span>
  const Text = ({ children }) => <p className={`mb-8`}>{children}</p>
  const HyperLink = ({ children, to }) => <a href={to} className={`underline`}>{children}</a>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [INLINES.HYPERLINK]: (node, children) => <HyperLink to={node.data.uri}>{children}</HyperLink>
    },
    renderText: text => text.replace('!', '?'),
  }
  const mainBody = documentToReactComponents(props.data.allContentfulAbout.nodes[0].childContentfulAboutMainBodyRichTextNode.json, options)
  const pad = `p-6`
  const Body = ({ className }) => <div className={`${styles.paragraph} ${className} text-left p-4 lg:p-6 lg:pt-0 pb-8 lg:pb-16`}>{mainBody}</div>
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    const el = parent.current
    const hero = [...el.getElementsByClassName('hero-image')]
    if (hero) {
      hero.forEach(item => {
        item.style.width = `${window.innerWidth}px`
        item.style.height = `${window.innerHeight * 0.85}px`
      })
    }
  })
  return (
    <Layout>
      <SEO
        title={toTitleCase(intl(`about`))}
        lang={props.pageContext.code}
        pathname={props.location.pathname}
      />
      <div className={styles.parent} ref={parent}>
        <div className={`desktop hidden lg:block`}>
          <div className={`w-4/7 h-screen fixed top-0 left-0`}>
            <Img
              loading={`eager`}
              imgStyle={{objectFit: 'cover'}}
              className={styles.img}
              fluid={getSources(mainImage)}
            />
          </div>
          <section className={`w-3/7 bg-white float-right`}>
            <h1 className={`text-left fixed ${pad} pt-4 uppercase text-base`}>{title}</h1>
            <div className={`h-32`}></div>
            <Body />
          </section>
        </div>
        <div className={`block lg:hidden mobile`}>
          <LoopScroll disableInDesktop fixedTargetIndex={1}>
            <h1 className={`fixed z-20 uppercase text-base p-4 pl-logo`}>{intl('about')}</h1>
            <div className={`w-full h-screen overflow-hidden hero-image`}>
              <Img loading={`eager`} className={styles.img} imgStyle={{objectFit: `cover`}} fluid={getSources(mainImage)} />
            </div>
            <div className={`h-2`}></div>
            <div className={`pl-1`}><Body/></div>
          </LoopScroll>
        </div>
      </div>
    </Layout>
  )
}

export const AboutQuery = graphql`
  query AboutQuery($code: String) {
    allContentfulAbout(filter: {node_locale: {eq: $code}}) {
      nodes {
        title
        mainImage {
          desktop {
            fluid(maxWidth: 2200, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          mobile {
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        childContentfulAboutMainBodyRichTextNode {
          json
        }
      }
    }
  }
`

export default AboutPage
